.butt {
  height: 30px;
  width: 232px;
}

.info {
  margin-left: 0px;
}

.butt3 {
  height: 30px;
  width: 250px;
}

.butt2 {
  height: 30px;
  width: 65px;
}
