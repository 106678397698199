* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.theme-dark {
  --dark-text: #ffffff; /*changed*/
  --light-text: #ffffff; /*changed*/
  --dark-background: #111724; /*changed*/
  --light-background: #586f7c;
  --accent: #b8dbd9;
  --button-border: #b8dbd9;
}

.theme-light {
  --dark-text: #5e4b56;
  --light-text: #5e4b56;
  --dark-background: #dbe7e4;
  --light-background: #eddcd2;
  --accent: #dbe7e4;
  --button-border: #5e4b56;
}

.appBar {
  background-color: var(--dark-background);
  background: var(--dark-background);
  color: var(--dark-background);
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* .example::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for IE and Edge */
/* .example {
  -ms-overflow-style: none;
} */

/* html,
#root {
  background-color: var(--dark-background);
  color: var(--dark-text);
}

nav {
  background-color: var(--dark-background);
  color: var(--light-text);
}

.main-container {
  background-color: var(--light-background);
} */
/* 
::-webkit-scrollbar {
  display: none;
}

.scrollhost::-webkit-scrollbar {
  display: none;
}

.scrollhost ::-moz-scrollbar {
  display: none;
}

.scrollhost {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent; 
} */

/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.example {
  -ms-overflow-style: none;
}
