.formControl {
  width: 30%;
  margin-bottom: 15px !important;
  background-color: rgba(28, 29, 31, 1);
}

.drop {
  margin: 0 1% !important;
  width: 140px !important;
  /* margin-top: 19px !important; */
}

.drop2 {
  /* margin: 0 1% !important; */
  height: 35px !important;
  background-color: rgba(16, 23, 37, 1) !important;
  width: 225px !important;
  color: #fff;
  font-size: 0.9rem;
  /* margin-top: 19px !important; */
}

.menu {
  margin-top: 18px !important;
  background: #1c1d1f;
  color: white;
  height: 40% !important;
  max-height: 5px !important;
}

.space {
  width: 150px !important;
}

.butt {
  height: 30px !important;
  width: 60px !important;
}

.butt2 {
  height: 30px !important;
  width: 105px !important;
}

.butt3 {
  height: 30px !important;
  width: 135px !important;
}

.recent {
  margin-top: 39px !important;
  margin-left: 200px !important;
  height: 100%;
}
