.forward {
  margin-top: 25px;
  margin-bottom: 15px;
  height: 30px;
  width: 232px;
}

.active {
  margin-top: 25px;
  height: 30px;
  /* width: 232px; */
}
