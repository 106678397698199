.react-datepicker__header {
  text-align: center;
  background-color: #f1e0c5;
  border-bottom: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 8px;
  position: relative;
}

/* Color of names and dates */
.react-datepicker__day,
.react-datepicker__time-name {
  color: #fff;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__day-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #101725;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #f1e0c5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "\00d7";
}

/* Selected dates color */
.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range {
  background-color: #f1e0c5;
}

/* Selected Day hover highlight color */
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #f1e0c5;
}

/* Selected Day color */
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #f1e0c5;
  color: #000;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #f1e0c5;
  color: #000;
}

.react-datepicker__navigation {
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  /* top: 15px; */
  width: 0;
  border: 0.45rem solid transparent;
  /* color: red; */
  /* background-color: red; */
  /* border-top-color: red; */
}
.react-datepicker__navigation--previous {
  left: 40px;
  top: -10px;
  border-bottom-color: #000;

  /* border-right-color: red; */
  /* border-color: red; */
  /* background-color: red; */
  /* color: red; */
}
.react-datepicker__navigation--next {
  right: 40px;
  top: 15px;
  border-top-color: #000;
}

/* Arrow on top */
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: #f1e0c5;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #f1e0c5;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range,
.react-datepicker__month--selecting-range
  .react-datepicker__month-text--in-range,
.react-datepicker__month--selecting-range
  .react-datepicker__quarter-text--in-range,
.react-datepicker__month--selecting-range
  .react-datepicker__year-text--in-range {
  background-color: red;
  color: red;
}
