.title{
    margin: 2% 2% !important;
    height: 70%;
    max-width: 300px !important;
}

.subtitle{
    margin: 2% 2% !important;
    height: 70%;
    max-width: 300px !important;
}

.table{
    margin: 8% 2% !important;
}