.formControl {
  width: 30%;
  margin-bottom: 15px !important;
  background-color: rgba(28, 29, 31, 1);
}

.drop {
  max-width: 250px;
}
.drop2 {
  min-width: 250px;
  margin-top: 25px;
}

.menu {
  /* background: #1c1d1f; */
  color: white;
  margin-right: 25px !important;
  margin-left: 25px !important;
  min-width: 225px !important;
  /* max-width: 250px; */
}

.space {
  width: 150px !important;
}

.butt {
  height: 30px;
  width: 75px;
}

.butt2 {
  height: 30px;
  width: 250px;
}

.butt3 {
  height: 30px;
  width: 250px;
}

.recent {
  margin-top: 39px !important;
  margin-left: 200px !important;
  height: 100%;
}
.forward {
  height: 30px;
  width: 232px;
}
