.formControl {
    width: 30%;
    margin-bottom: 30px !important;
    background-color: rgba(28,29,31,1);
}
.total{
    margin-left: 7px !important;
}

.total2{
    margin-left: 7px !important;
    margin-top: 10px !important;
}

.title{
    margin-top: 4px !important;
    margin-bottom: 0px !important;
}
.title2{
    margin-top: 10px !important;
    margin-bottom: 0px !important;
}

.card{
    margin: 3% .5% !important;
    height: 70%;
    min-height: 400px !important;
    max-height: 400px !important;
}


.drop{
    margin: 0 1% !important;
    margin-top: 19px !important;
}
.drop2{
    margin: 0 1% !important;
    margin-top: 19px !important;
    min-width: 150px !important;
    max-height: 20px !important;
}

.menu {
    margin-top: 18px !important;
    background: #1c1d1f;
    color: white;
    margin-bottom: 25px !important;
    max-height: 20px !important;
  }

.space{
    width: 150px !important;
}

.butt{
    margin-bottom: 50px !important;
    margin: 0 1% !important;
    margin-top: 35px !important;
    height: 40%;
    margin-right: 5px !important;
    margin-left: 5px !important;
    max-height: 20px !important;
}

.butt2{
    margin-bottom: 30px !important;
    margin: 0 1% !important;
    margin-top: 30px !important;
    height: 40%;
    margin-right: 5px !important;
    margin-left: 100px !important;
}


.recent{
    margin-top: 39px !important;
    margin-left: 200px !important;
    height: 100%;
}