body {
  background-color: rgb(20, 20, 20);
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.paper {
  padding: 25px;
  display: "flex";
  flex-direction: "column";
  justify-content: "flex-start";
}

.image {
  width: 370px;
  margin-top: 50px;
}
.title {
  margin: 0 1% !important;
  height: 50%;
  max-width: 300px !important;
}

.subtitle {
  margin: 2% 2% !important;
  height: 70%;
  max-width: 300px !important;
}

@media (max-width: 770px) {
  .container {
    margin: 0 10%;
  }
  .image {
    width: 100%;
  }
}
