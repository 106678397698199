.title {
  margin: 0 1% !important;
  height: 50%;
  max-width: 300px !important;
}

.subtitle {
  margin: 4% 2% !important;
  height: 70%;
  max-width: 300px !important;
}

.table {
  margin: 8% 2% !important;
}

.drop {
  /* padding-bottom: 200px !important; */
}

.drop2 {
  /* margin: 0 1% !important; */
  height: 35px !important;
  background-color: rgba(25, 40, 65, 1) !important;
  width: 175px;
  color: #fff;
  font-size: 0.95rem;
  margin-top: 20px;
  /* padding-left: 40px; */

  /* margin-top: 19px !important; */
}
