:root {
  /** sunny side **/
  --blue-background: #c2e9f6;
  --blue-border: #72cce3;
  --blue-color: #96dcee;
  --yellow-background: #fffaa8;
  --yellow-border: #f5eb71;
  /** dark side **/
  --indigo-background: #808fc7;
  --indigo-border: #5d6baa;
  --indigo-color: #6b7abb;
  --gray-border: #e8e8ea;
  --gray-dots: #e8e8ea;
}

@keyframes reverse {
  0% {
    left: 47px;
    width: 20px;
  }
  60% {
    left: 3px;
    width: 40px;
  }
  100% {
    left: 3px;
  }
}

@keyframes switch {
  0% {
    left: 3px;
  }
  60% {
    left: 3px;
    width: 40px;
  }
  100% {
    left: 30px;
    width: 20px;
  }
}

.toggle--checkbox {
  opacity: 0;
}

.toggle--checkbox:focus + .toggle--label {
  outline: solid 3px var(--button-border);
}

.container--toggle {
  display: grid;
  place-items: center;
  /* min-height: 60px; */
  position: relative;
  grid-area: toggle;
  margin-right: 20px;
  margin-bottom: 10px;
  /* margin: 20px; */
}

/* background */
.toggle--label {
  width: 60px;
  height: 30px;
  background: var(--indigo-color);
  border-radius: 100px;
  border: 3px solid var(--indigo-border);
  display: flex;
  position: relative;
  transition: all 350ms ease-in;
  color: var(--indigo-color);
}

.toggle--checkbox:checked + .toggle--label {
  background: var(--blue-color);
  color: var(--blue-color);
  border-color: var(--blue-border);
}

/* sun and moon */
.toggle--checkbox:checked + .toggle--label:before {
  animation-name: reverse;
  animation-duration: 350ms;
  animation-fill-mode: forwards;
  transition: all 360ms ease-in;
  background: var(--yellow-background);
  border-color: var(--yellow-border);
}

.toggle--label:before {
  animation-name: switch;
  animation-duration: 350ms;
  animation-fill-mode: forwards;
  content: "";
  width: 20px;
  height: 20px;
  border: 3px solid var(--gray-border);
  top: 1px;
  left: 10px;
  position: absolute;
  border-radius: 25px;
  background: white;
}

/* moon dimples */
.toggle--label:after {
  transition-delay: 0ms;
  transition: all 250ms ease-in;
  position: absolute;
  content: "";
  box-shadow: var(--gray-dots) -14px 0 0 1px, var(--gray-dots) -17px 6px 0 -1px;
  left: 51px;
  top: 6px;
  width: 4px;
  height: 4px;
  background: transparent;
  border-radius: 50%;
  opacity: 1;
}

.toggle--checkbox:checked + .toggle--label:after {
  transition-delay: 50ms;
  opacity: 0;
}

/* clouds */
.toggle--checkbox:checked + .toggle--label .toggle--label-background {
  width: 5px;
  left: 35px;
  top: 10px;
}

.toggle--checkbox:checked + .toggle--label .toggle--label-background:before {
  top: -2px;
  left: -2px;
  width: 12px;
  height: 3px;
}

.toggle--checkbox:checked + .toggle--label .toggle--label-background:after {
  top: 3px;
  width: 12px;
  height: 3px;
  left: -6px;
}

/* stars */
.toggle--label-background {
  border-radius: 4px;
  position: relative;
  background: white;
  left: 17px;
  width: 4px;
  transition: all 150ms ease-in;
  top: 10px;
  height: 4px;
}

.toggle--label-background:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  top: -5px; /* top left */
  border-radius: 5px;
  background: white;
  left: -13px; /* top left */
  transition: all 150ms ease-in;
}

.toggle--label-background:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  left: -13px; /* bottom left */
  top: 5px; /* bottom left */
  border-radius: 5px;
  background: white;
  transition: all 150ms ease-in;
}
